<template>
  <Layout>
    <PageHeader
      :title="title"
      moda_title="Datos del operador"
      ref="pageForm"
      :items="items"
    >
    </PageHeader>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t('filter.from') }}: </span>
                  <vs-input
                    v-model="filterData.fromDate"
                    type="date"
                  ></vs-input>
                </label>
              </div>
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t('filter.until') }}: </span>
                  <vs-input
                    v-model="filterData.toDate"
                    @change="change"
                    type="date"
                  ></vs-input>
                </label>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-12 d-flex justify-content-start">
                <label
                  class="d-inline-flex align-items-center"
                  style="width: max-content"
                >
                  {{ $t('filter.gameType') }}:
                </label>
                <select
                  v-model="filterData.gameType"
                  name="client"
                  class="form-select form-select-sm my-2 ms-2 border-0 shadow-lg w-50"
                  placeholder="Seleccione.."
                >
                  <option disabled selected>
                    {{ $t('helpers.select') }}...
                  </option>
                  <option
                    v-for="(item, index) in gamesTypes"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="col-sm-12 col-md-4" v-if="filterData.gameType">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t('filter.currency') }}:
                  <select
                    v-model="filterData.currencyId"
                    name="currency"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione.."
                    @change="filtrar"
                  >
                    <option disabled selected>
                      {{ $t('helpers.select') }}...
                    </option>
                    <option
                      v-for="{ _id, name } in currencies"
                      :key="_id"
                      :value="_id"
                    >
                      {{ name }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
            <div class="d-flex align-items-center mt-2">
              <vs-button
                type="button"
                @click="filtrar()"
                :disabled="!(filterData.gameType && filterData.currencyId)"
              >
                {{ $t('filter.filter') }}
              </vs-button>
              <vs-button
                type="button"
                success
                @click="cancelFilter()"
                :disabled="!Object.entries(filterData).length"
              >
                {{ $t('filter.cancel') }}
              </vs-button>
            </div>
            <div class="row mt-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.show') }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option
                        v-for="(op, index) in pageOptions"
                        :key="index"
                        :label="op"
                        :value="op"
                        >{{ op }}</vs-option
                      >
                    </vs-select>
                    &nbsp;{{ $t('filter.entries') }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center m-2">
                    {{ $t('filter.search') }}:
                    <vs-input
                      v-model="filter"
                      type="search"
                      primary
                      class="shadow-lg"
                    ></vs-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
              <!-- <TotalRegister :totalRegister="totalRegister"></TotalRegister> -->
            </div>
            <!-- Table -->
            <b-overlay :show="loading" rounded="lg">
              <div class="table-responsive mb-0" v-if="tableData.length">
                <b-table
                  :items="tableData"
                  :fields="fields"
                  responsive="sm"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :sort-by="sortBy"
                  :sort-desc="sortDesc"
                >
                  <template #cell(usdExchange)="data">
                    {{ data.item.usdExchange && data.item.usdExchange }}
                  </template>
                  <template #cell(createdAt)="data">
                    <div
                      class="createdAt"
                      @mouseover="handleOver"
                      @mouseleave="handleLeave"
                    >
                      {{
                        moment(data.item.createdAt).format(
                          'DD/MM/YYYY | HH:mm:ss'
                        )
                      }}
                      <span class="badge badge-time bg-primary rounded-pill"
                        >Server time:
                        {{
                          moment
                            .utc(data.item.createdAt)
                            .format('DD/MM/YYYY | HH:mm:ss')
                        }}</span
                      >
                    </div>
                  </template>
                  <template #cell(details)="data">
                    <div>
                      <b-button
                        variant="outline-primary"
                        @click="openDetails(data.item)"
                        >{{ $t('helpers.look') }}</b-button
                      >
                    </div>
                  </template>
                  <div v-if="!tableData.length" class="text-center">
                    {{ $t('tableReports.noResults') }}
                  </div>
                </b-table>
              </div>
            </b-overlay>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <button
                      class="btn btn-secondary mx-2 uil-angle-left"
                      @click="beforePage"
                      :disabled="!skip"
                    ></button>
                    <button
                      class="btn btn-primary mx-2 uil-angle-right"
                      @click="nextPage"
                      :disabled="tableData.length < limit"
                    ></button>
                  </ul>
                  <!-- <pagination
                    :totalPages="totalPages"
                    :currentPage="currentPage"
                  ></pagination> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main';
import PageHeader from '@/components/page-header-simple';
// import downloadexcel from "vue-json-excel";
import Swal from 'sweetalert2';
import appConfig from '@/app.config';
import { breadCrumbData } from '../../../../helpers/breadcrumbs/breadCrumbs';
import { mapActions, mapGetters } from 'vuex';

/**
 * Users-table component
 */
export default {
  page: {
    title: 'Operators Table',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    // downloadexcel
  },
  data() {
    return {
      skip: 0,
      limit: 10,
      fileName: 'CurrenciesFluctutations.xls',
      json_fields: {
        Currency: 'name',
        Short: 'short',
        Symbol: 'symbol',
        Usd: 'usdExchange',
        Date: 'createdAt',
      },
      tableData: [],
      totalRegister: 0,
      totalPages: '...',
      title: this.$t('sidebar.reports.subItems.currencyFluctuation'),
      items: breadCrumbData.fluctuation,
      loading: false,
      filterData: {},
      dataDetails: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: ['name', 'short'],
      currencies: [],
      sortBy: 'createdAt',
      sortDesc: true,
      fields: [
        {
          key: 'name',
          sortable: true,
          label: this.$t('tableReports.currency').toUpperCase(),
          class: 'text-end',
        },
        {
          key: 'short',
          sortable: true,
          label: this.$t('currencies.abbreviation').toUpperCase(),
          class: 'text-end',
        },
        {
          key: 'symbol',
          sortable: true,
          label: this.$t('currencies.symbol').toUpperCase(),
          class: 'space-nowrap text-end',
        },
        {
          key: 'usdExchange',
          sortable: true,
          label: this.$t('tableReports.rateUSD').toUpperCase(),
          class: 'space-nowrap text-end',
        },
        {
          key: 'createdAt',
          sortable: true,
          label: this.$t('table.data').toUpperCase(),
          class: 'text-end',
        },
      ],

      typeform: {
        name: '',
        minBet: '',
        maxBet: '',
        client: '',
      },
      clientFilter: null,
      disabledNextPage: null,
      disabledPreviousPage: null,
      downloadSelected: 'true',
      games: [],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    ...mapGetters({
      user: 'user/getUserData',
      mapGames: 'games/getGames',
      mapCurrencies: 'currencies/getCurrencies',
      mapFluctuation: 'currencies/getFluctuation',
      gamesTypes: 'reports/getGamesTypes',
    }),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getCurrencies();
  },
  methods: {
    handleOver(e) {
      const element = e.target;
      const badge = element.querySelector('.badge-time');
      if (badge) {
        badge.style.visibility = 'visible';
      }
    },
    handleLeave(e) {
      const element = e.target;
      const badge = element.querySelector('.badge-time');
      if (badge) {
        badge.style.visibility = 'hidden';
      }
    },
    async filtrar() {
      this.loading = true;

      const params = {
        page: this.currentPage,
        limit: this.limit,
        ...this.filterData,
      };

      await this.fetchFluctuation({ params });

      this.tableData = this.mapFluctuation;
      this.loading = false;
    },
    cancelFilter() {
      this.filterData = {};
      this.currentPage = 1;
      this.tableData = [];
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getCurrencies() {
      try {
        await this.fetchCurrencies({
          role: this.user.role.name,
          user: this.user,
        });
        this.currencies = this.mapCurrencies;
      } catch (error) {
        console.log(error);
      }
    },
    nextPage() {
      this.skip += this.perPage;
      this.currentPage++;
      this.filtrar();
    },
    beforePage() {
      this.skip -= this.perPage;
      this.currentPage--;
      this.filtrar();
    },
    async exportData() {
      const { data } = await this.$http.get(
        '/currencies/usd-exchange/fluctuation',
        {
          params: {
            ...this.filterData,
            limit: this.limit,
            page: this.currentPage,
            // pagination: this.downloadSelected,
          },
        }
      );
      if (data.ok) {
        return data.historyFluctuation;
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'danger',
          title: 'Advertencia, no se encuentran datos asociados',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
    closeModa() {
      this.$refs.pageForm.modal = false;
      this.typeform = {
        name: '',
        minBet: '',
        maxBet: '',
        client: '',
      };
      this.filtrar();
    },
    openDetails(item) {
      this.dataDetails = item;
    },
    change() {
      this.filtrar();
    },
    ...mapActions({
      fetchCurrencies: 'currencies/fetchCurrencies',
      fetchFluctuation: 'currencies/fetchFluctuation',
    }),
  },
  watch: {
    perPage() {
      this.skip = 0;
      this.limit = this.perPage;
      this.filtrar();
    },
  },
  middleware: 'authentication',
};
</script>

<style>
.createdAt {
  position: relative;
}

.badge-time {
  position: absolute;
  right: 0;
  top: -12px;
  visibility: hidden;
}

.space-nowrap {
  white-space: nowrap !important;
}
</style>
